<style>

</style>
<script>
import { VclList } from 'vue-content-loading';

import CopyableInput from "@/components/CopyableInput";
import {mapGetters} from "vuex";
import CopyableText from "@/components/CopyableText.vue";

export default {
  props: ['cftools_id'],
  components: {
	  CopyableText,
    VclList
  },
	computed: {
		...mapGetters({
			getServers: 'getServers',
			getSettings: 'getSettings',
			getDTLocale: 'getDTLocale'
		}),
		serverMap() {
			let servers = {};
			this.getServers().forEach((server) => {
				servers[server.id] = server;
			});
			return servers;
		},
		filteredTraces: function() {
			if(!this.traces) return [];

			const skip = (this.page - 1) * this.perPage;
			const limit = (this.page) * this.perPage;

			let hits = 0;
			let counter = 0;
			let results = [];

			this.traces.forEach((trace) => {
				if(this.filter !== null && this.filter.length) {
					if(trace.key.toLowerCase().includes(this.filter.toLowerCase()) || this.filter.toLowerCase().includes(trace.key.toLowerCase())) {
						if(counter >= skip && counter < limit) {
							results.push(trace);
						}
						counter++;
					}
				} else {
					if(counter >= skip && counter < limit) {
						results.push(trace);
					}
					counter++;
				}
			});
			return results;
		},
		pages() {
			return (this.filter !== null && this.filter) ? Math.ceil(this.filteredTraces.length / this.perPage) : Math.ceil(this.traces.length / this.perPage) ;
		}
	},
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/traces`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
					this.meta.features = data.features;
					this.meta.results = data.results;
          this.traces = data.traces;

					this.$nextTick(() => {
						this.ready = true;
					})
        })
        .catch(error => {
          this.handleError(error);
        });
    },
	  canGoBackward() {
			return this.page > 1;
	  },
		canGoForward() {
			return this.page < this.pages;
		},
		goBackward() {
			this.page--;
		},
	  goForward() {
			this.page++;
	  }
  },
	created() {
		this.servers = this.serverMap;
	},
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      traces: null,
	    meta: {
				features: null,
		    results: null
	    },
	    servers: {},
	    page: 1,
	    perPage: 5,
	    filter: null
    }
  }
};
</script>

<template>
  <div class="margin-right: 1rem;">
    <template v-if="ready">
	    <div class="row">
		    <div class="col-8">
			    <div class="row mb-4">
				    <div class="col">
					    <b-input class="form-control text-white hard-border" size="lg" type="text" v-model="filter" @input="page = 1" :placeholder="$t('profile.identities.traces.search.placeholder')"/>
				    </div>
			    </div>

		      <template v-for="trace in filteredTraces">
				    <div class="row" :key="`${trace.server_id}-${trace.key}`">
					    <div class="col">
						    <div class="card">
							    <div class="card-body">
								    <div class="media">
									    <div class="row w-100">
										    <div class="col-2">
											    <div class="text-center">
												    <template v-if="['legacy_player_name', 'player_name'].includes(trace.type)">
													    <div class="h-100">
														    <div class="mb-n1 mt-1">
															    <div>
																    <i class="fal fa-user font-size-24 text-white"/>
															    </div>
															    <button class="btn btn-sm text-uppercase mt-2 mb-2 btn-light">
																    <h6 class="mb-0">
																	    {{ $t('profile.identities.traces.types.player_name') }}
																    </h6>
															    </button>
														    </div>
													    </div>
												    </template>
												    <template v-else-if="['legacy_ipv4', 'ipv4'].includes(trace.type)">
													    <div class="h-100">
														    <div class="mb-n1 mt-1">
															    <div>
																    <i class="fal fa-network-wired font-size-24 text-white"/>
															    </div>
															    <button class="btn btn-sm text-uppercase mt-2 mb-2 btn-light">
																    <h6 class="mb-0">
																	    {{ $t('profile.identities.traces.types.ipv4') }}
																    </h6>
															    </button>
														    </div>
													    </div>
												    </template>
												    <template v-else-if="['persona_name'].includes(trace.type)">
													    <div class="h-100">
														    <div class="mb-n1 mt-1">
															    <div v-if="servers[trace.server_id] && isSteamTitle(servers[trace.server_id].game)">
															      <i class="fab fa-steam font-size-24 text-white"/>
															    </div>
															    <div v-else>
																    <i class="fal fa-user-circle font-size-24 text-white"/>
															    </div>
															    <button class="btn btn-sm text-uppercase mt-2 mb-2 btn-light">
																    <h6 class="mb-0">
																	    {{ $t('profile.identities.traces.types.persona_name') }}
																    </h6>
															    </button>
														    </div>
													    </div>
												    </template>
											    </div>
										    </div>
										    <div class="col-10 w-100 h-75 justify-content-center">
											    <div class="row h-50">
												    <div class="col mt-auto mb-auto pl-0">
													    <h4 class="m-0 p-0">
														    <CopyableText :text="trace.key"/>
														    <template v-if="['legacy_player_name', 'legacy_ipv4'].includes(trace.type)">
															    <span class="badge badge-light text-white font-size-11">
																    <i class="fad fa-archive"/>
																    {{ $t('profile.identities.traces.legacy') }}
															    </span>
														    </template>
													    </h4>
												    </div>
											    </div>
											    <div class="row h-50 mt-1">
												    <div class="col mt-auto mb-auto pl-0">
													    <div class="row">
														    <div class="col">
															    <h5 class="font-size-15 m-0 p-0">
																    {{ trace.usages.length }}
															    </h5>
															    <p class="text-muted mb-0">
																    {{ $t('profile.identities.traces.usages') }}
															    </p>
														    </div>
														    <div class="col">
															    <h5 class="font-size-15 m-0 p-0">
																    {{ $d(parseDate(trace.updated_at), 'datetime', getDTLocale()) }}
															    </h5>
															    <p class="text-muted mb-0">
																    {{ $t('profile.identities.traces.last_use') }}
															    </p>
														    </div>
														    <div class="col">
															    <h5 class="font-size-15 m-0 p-0">
																    {{ $d(parseDate(trace.created_at), 'datetime', getDTLocale()) }}
															    </h5>
															    <p class="text-muted mb-0">
																    {{ $t('profile.identities.traces.first_use') }}
															    </p>
														    </div>
														    <div class="col">
															    <h5 class="font-size-15 m-0 p-0">
																    <template v-if="servers[trace.server_id]">
																	    <span class="badge badge-light text-white">
																		    <img :src="'/' +gameIcon(servers[trace.server_id].game)" alt="server game logo" class="logo-img" height="16px" width="16px">
																	      <span class="h6 m-0 p-0">
																		      {{ servers[trace.server_id].identifier }}
																	      </span>
																	    </span>
																    </template>
																    <template v-else>
																	    <span class="badge badge-light text-white" v-b-tooltip.hover :title="$t('profile.identities.traces.unavailable_server.disclaimer')">
																		    <i class="fad fa-info-circle"/>
																				{{ $t('profile.identities.traces.unavailable_server.text') }}
																	    </span>
																    </template>
															    </h5>
															    <p class="text-muted mb-0">
																    {{ $t('profile.identities.traces.server') }}
															    </p>
														    </div>
													    </div>
												    </div>
											    </div>
										    </div>
									    </div>
								    </div>
							    </div>
						    </div>
					    </div>
				    </div>
		      </template>
			    <div class="row mt-2 ">
				    <div class="col text-center">
					    <b-button-group size="sm">
						    <b-button class="btn btn-light text-white" :disabled="!canGoBackward()" @click="goBackward()">
							    <span class="text-white p-1">
								    <i class="fas fa-arrow-left"/>
							    </span>
						    </b-button>
						    <b-button variant="primary" class="btn btn-primary text-black">
									<h5 class="text-white m-0 p-1">
								    {{ this.page }}
							    </h5>
						    </b-button>
						    <b-button class="btn btn-light" :disabled="!canGoForward()" @click="goForward()">
							    <span class="text-white p-1">
								    <i class="fas fa-arrow-right"/>
							    </span>
						    </b-button>
					    </b-button-group>
				    </div>
			    </div>
		    </div>
		    <div class="col-4">
			    <div class="card card-body">
				    <div class="row">
					    <div class="col">
						    <h5 class="font-size-15 m-0 p-0">
							    {{ traces.length }}
						    </h5>
						    <p class="text-muted mb-0">
							    {{ $t('profile.identities.traces.results') }}
						    </p>
					    </div>
				    </div>
				    <template v-if="!meta.features.global_intelligence && meta.results.filtered >= 1">
					    <div class="row mt-3 mb-n4">
						    <div class="col">
							    <div class="card border border-light">
								    <div class="card-body">
									    <h4 class="alert-heading text-white">
										    <i class="fad fa-telescope"/>
										    {{ $t('profile.identities.traces.upsell.title', {filtered: meta.results.filtered}) }}
									    </h4>
									    <hr/>
									    <p class="h6 m-0 p-0">
										    {{ $t('profile.identities.traces.upsell.text', {plan: 'Community'}) }}
									    </p>
								    </div>
							    </div>
						    </div>
					    </div>
				    </template>
			    </div>
		    </div>
	    </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="card">
                <div class="card-body text-center">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" width="400px"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
